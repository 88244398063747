.cal-card{
  margin: 5% 8% !important;
}

@media screen and(min-width: 1920px) {
  .flex-grid-dashboard {
    display: flex;
    /* if not enough room, go to next row  */
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 640px){
  .cal-card{
    margin: 5% 8% !important;
  }
}

@media screen and (min-width: 320px){
  .cal-card{
    margin: 10% 0.5% !important;
  }
}
